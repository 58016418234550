.activity-button {
  position: fixed;
  z-index: 99;
  width: 100%;
  bottom: 10px;
  left: 0;
  padding: 0 16px;
  box-sizing: border-box;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  & > a {
    display: block;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.09);
    color: rgba(0, 0, 0, 0.85);
    background: #fff;
  }
  .submit {
    background: #202078;
    color: rgba(255, 255, 255, 1);
    border: none;
    border-radius: 20px;
  }
  .grey {
    background: rgba(231, 231, 231, 1);
    border-radius: 20px;
    color: rgba(0, 0, 0, 0.35);
    border: none;
  }
}

.activity-form {
  .am-list-body {
    padding-right: 16px;
  }
  .am-list-item {
    min-height: 50px;
    .am-input-label.am-input-label-5 {
      width: auto;
      margin-right: 8px;
    }

    .am-input-control input {
      font-size: 14px;
      line-height: 20px;
    }
    .am-list-line {
      padding-right: 0;
      .am-list-extra {
        flex-basis: auto;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
  .activity-top {
    .am-list-line {
      padding-left: 8px;
      .title {
        font-size: 16px;
        font-weight: 400;
        color: rgba(47, 47, 77, 1);
        line-height: 22px;
      }
    }
    .am-input-control input {
      text-align: right;
      padding-right: 16px;
    }
    .photo-box {
      width: 53px;
      height: 80px;
      border: 2px dashed rgba(0, 0, 0, 0.15);
      background-image: url(../images/imageIcon.png);
      background-size: 24px;
      background-repeat: no-repeat;
      background-position: center;
      display: inline-block;
      vertical-align: middle;
      margin-right: 16px;
    }
    .photo-desc {
      vertical-align: middle;
      display: inline-block;
    }
  }
  .activity-desc {
    margin-top: 16px;
    .am-list-item.am-input-item {
      height: 60px;
    }
  }
}

.activity-notice {
  margin: 16px;
  padding: 16px;
  background: rgba(255, 255, 255, 1);
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.04);
  display: block;
  .activity-notice-item {
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
    display: block;
    .title {
      font-size: 16px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 22px;
      margin-bottom: 8px;
    }
    .date {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      line-height: 20px;
      margin-bottom: 16px;
    }
    .desc {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 20px;
    }
  }
  .opt {
    padding-top: 16px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.activity-notice-detail {
  padding: 16px;
  background: #ffffff;
  .top {
    border-bottom: 1px solid #f5f5f5;
    margin-bottom: 16px;
    padding-bottom: 8px;
    .title {
      font-size: 20px;
      font-weight: 500;
      color: rgba(51, 51, 51, 1);
      line-height: 28px;
      margin-bottom: 8px;
    }
    .date {
      font-size: 12px;
      font-weight: 400;
      color: rgba(153, 153, 153, 1);
      line-height: 17px;
    }
  }
  .content {
    font-size: 16px;
    font-weight: 400;
    color: rgba(56, 56, 56, 1);
    line-height: 26px;
  }
}
/* 头部返回栏 */

.back-container {
  position: relative;
  height: 44px;
}

.back-to-upper {
  position: fixed;
  text-align: center;
  height: 44px;
  line-height: 44px;
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  z-index: 99;
  left: 0;

  .backIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
    z-index: 1;
  }

  > div {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
  }

  .create-activity {
    font-size: 17px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #202078;
    line-height: 24px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    z-index: 1;
  }
}

.activity-modal {
  background: rgba(0, 0, 0, 0.35);
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  .modal-container {
    width: 270px;
    height: 107px;
    background: rgba(255, 255, 255, 1);
    border-radius: 7px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .modal-title {
      font-size: 16px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 22px;
      padding: 17px 0;
      text-align: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.09);
    }
    .modal-btn {
      display: flex;
      & > div {
        width: 50%;
        text-align: center;
        padding: 16px 0;
        font-size: 18px;
      }
      .comfirm {
        color: #202078;
        position: relative;
        &::before {
          content: "";
          width: 1px;
          height: 50px;
          background: rgba(0, 0, 0, 0.09);
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }
}

// 活动签到
.activity-sign {
  padding: 0 24px;
  margin-top: 40px;
  overflow: hidden;
  .activity-img {
    width: 94px;
    height: 138px;
    margin: 24px auto;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .activity-title {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 28px;
    margin-bottom: 24px;
  }
  .activity-info {
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    padding: 16px;
    & > div {
      margin-bottom: 6px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      line-height: 20px;
      display: flex;
      align-items: center;
      & > img {
        margin-right: 4px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .activity-sign-btn {
    margin-top: 24px;
    padding: 10px 0;
    background: #202078;
    border-radius: 20px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    line-height: 20px;
  }
}
