@charset "utf-8";
.register-section {
  padding: 24px 24px 0;
  .register-title {
    margin: 0 0 8px 16px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(47, 47, 77, 1);
    line-height: 22px;
  }
  .am-list-line {
    padding-right: 8px;
  }
  input {
    width: 100%;
  }
  .info-detail-picker {
    height: 50px;
    padding: 0 8px;
    .am-list-content {
      display: flex;
    }
    .icon {
      margin-left: 8px;
      margin-right: 16px;
    }
    .placeholder {
      font-size: 16px;
      color: rgba(47, 47, 77, 0.4);
      line-height: 24px;
    }
    .am-list-extra {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .am-list-item.am-input-item {
    height: 50px;
    padding: 0 8px;
  }
  .am-list-item .am-input-label.am-input-label-5 {
    margin-right: 16px;
    width: auto;
  }
  .am-list-item .am-input-extra {
    max-height: fit-content;
    margin-left: 8px;
  }
  .code-btn {
    color: #202078;
    height: 24px;
    border-radius: 2px;
    border: 1px solid #202078 !important;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    padding: 0 8px;
  }
  .register-btn {
    background: #202078;
    color: #fff;
  }
}
.register-tips {
  background: rgba(221, 56, 0, 0.1);
  margin-bottom: 16px;
  padding: 8px 0 8px 26px;
  border-radius: 6px;
  border: 1px solid rgba(198, 0, 13, 0.5);
  font-size: 12px;
  color: #2f2f4d;
  position: relative;
  line-height: 17px;
  &::before {
    content: "";
    background-image: url(../images/infoTips.png);
    width: 14px;
    height: 14px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    left: 8px;
    top: 9px;
  }
}
.register-section.info-form {
  .info-list {
    .am-list-body {
      padding: 0 8px 0 0;
    }
  }
}

// 注册结果页面
.register-result {
  text-align: center;
  padding-bottom: 64px;
  img {
    padding-top: 44px;
    display: block;
    margin: 0 auto 24px;
  }
  .register-result-title {
    font-size: 24px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 33px;
    margin-bottom: 15px;
  }
  .register-result-desc {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    line-height: 20px;
  }
  .register-result-btn {
    height: 40px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 40px;
    bottom: 40px;
    width: 100%;
    padding: 0 16px;
    left: 0%;
    display: block;
    box-sizing: border-box;
    margin-top: 16px;
    & > div {
      background: rgba(255, 255, 255, 1);
      border-radius: 20px;
      border: 1px solid rgba(0, 0, 0, 0.09);
    }
  }
}

// 注册页顶部流程
.connecting-section {
  display: flex;
  padding: 24px 24px 0;
  align-items: center;
  justify-content: space-between;
  .comfirm {
    font-size: 12px;
    font-weight: 400;
    color: #202078;
    line-height: 22px;
    display: flex;
    align-items: center;
    .done {
      background-image: url("../images/right.png");
      background-size: 9px auto;
      background-repeat: no-repeat;
      background-position: center;
      width: 24px;
      height: 24px;
      border: 1px solid #202078;
      border-radius: 50%;
      margin-right: 8px;
      box-sizing: border-box;
    }
  }
  .active {
    font-weight: 500;
    span {
      font-size: 14px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      line-height: 24px;
      background: #202078;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      text-align: center;
      margin-right: 8px;
    }
  }
  .notActive {
    color: rgba(32, 32, 120, 0.25);
    span {
      font-size: 14px;
      font-weight: 400;
      color: rgba(32, 32, 120, 0.25);
      line-height: 24px;
      border: 1px solid rgba(32, 32, 120, 0.15);
      width: 24px;
      height: 24px;
      border-radius: 50%;
      text-align: center;
      margin-right: 8px;
    }
  }
  .connecting-line {
    width: 32px;
    height: 1px;
    background: #202078;
  }
}
.chooseStu-section {
  padding: 24px 24px;
  .chooseStu-div {
    background: #fff;
    text-align: center;
    border-radius: 4px;
    padding-bottom: 16px;
    .chooseStu-title {
      padding-top: 24px;
      line-height: 22px;
      font-size: 16px;
      margin-bottom: 24px;
    }
    .chooseStu-std {
      padding: 0 40px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      span {
        display: inline-block;
        width: 42%;
        height: 28px;
        border-radius: 16px;
        border: 1px solid rgba(0, 0, 0, 0.25);
        font-size: 14px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.65);
        line-height: 28px;
        margin-bottom: 16px;
      }
      .active {
        background: #202078;
        color: #fff;
        border: none;
        height: 30px;
        line-height: 30px;
      }
    }
  }
  .chooseStu-btn {
    margin-top: 24px;
    width: 100%;
    display: block;
    background: #202078;
    border-radius: 4px;
    line-height: 44px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    text-align: center;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-button:before {
    border: none;
  }
  html:not([data-scale])
    .am-list-body
    div:not(:last-child)
    .info-checkBox
    .am-list-line {
    border-bottom: none;
  }
  html:not([data-scale]) .am-list-body div:not(:last-child) .am-list-line {
    border-bottom: 1px solid rgba(47, 47, 77, 0.1);
  }
}
