.password-update-section {
  padding: 24px;
  .title {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(47, 47, 77, 1);
    line-height: 22px;
    margin-bottom: 16px;
  }
  .per-info {
    padding: 16px;
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    margin-bottom: 16px;
    & > div {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      line-height: 20px;
      &:first-child {
        margin-bottom: 6px;
      }
      img {
        margin-right: 4px;
      }
    }
  }
  .password-tips {
    background: rgba(221, 56, 0, 0.1);
    border-radius: 6px;
    border: 1px solid rgba(198, 0, 13, 0.5);
    padding: 8px 0 8px 26px;
    margin-bottom: 16px;
    font-size: 12px;
    font-weight: 400;
    color: rgba(47, 47, 77, 1);
    line-height: 17px;
    position: relative;
    &::before {
      content: "";
      background-image: url(../images/infoTips.png);
      width: 14px;
      height: 14px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      left: 8px;
      top: 9px;
    }
  }
}

.password-reset-section {
  padding: 24px;
  .password-reset-title {
    font-size: 16px;
    font-weight: 500;
    color: rgba(47, 47, 77, 1);
    line-height: 22px;
    text-align: center;
    margin-bottom: 24px;
  }
}

// 密码模块的公共样式
.password-form {
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  margin-bottom: 24px;

  .am-list-item .am-input-label.am-input-label-5 {
    width: auto;
    margin-right: 16px;
  }
}
.password-section-btn {
  height: 50px;
  background: #202078;
  border-radius: 4px;
  line-height: 50px;
  color: #fff;
}
