/*@import '/antd-mobile/dist/antd-mobile.css';*/

@import "kitchen-sink.css";
body {
  margin: 0 auto;
  max-width: 640px;
  background-color: #f6f7fb;
  position: relative;
}
.App {
  margin: 0 auto;
  padding: 0;
  background: #f6f7fb;
  font-size: 16px;
  overflow: hidden;
  padding-bottom: env(safe-area-inset-bottom);
}
.paddingLeft8 {
  padding-left: 8px !important;
}
.marginLeft8 {
  margin-left: 8px !important;
}
@import "./header.scss";
@import "./navlist.scss";
@import "./login.scss";
@import "./register.scss";
@import "./info.scss";
@import "./main.scss";
@import "./banner.scss";
@import "./activity.scss";
@import "./password.scss";
