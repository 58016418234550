@charset "utf-8";
.login-section {
  padding: 32px 24px;
  input {
    width: 100%;
  }
  .am-list-item.am-input-item {
    height: 50px;
    padding: 0 8px;
  }
  .am-list-item .am-input-label.am-input-label-5 {
    margin-right: 16px;
    width: auto;
  }
  .login-submit {
    background-color: #202078;
    color: #fff;
    margin-top: 24px;
    margin-bottom: 16px;
  }
  .login-register {
    background-color: #202078;
    color: #fff;
    display: block;
  }
  .wxBtn {
    margin-top: 16px;
    font-size: 16px;
    img {
      margin-right: 7px;
      vertical-align: middle;
    }
  }
}
