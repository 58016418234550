// form 表单样式
.info-form {
  padding: 24px 24px 0;

  .info-list {
    margin-bottom: 16px;
    .am-list-body {
      padding: 0 16px 0 8px;
      .am-list-item {
        min-height: 50px;
      }
    }
    .info-list-item {
      padding-left: 8px;
      .icon {
        background-size: cover;
        height: 24px;
        width: 24px;
        float: left;
        margin-right: 16px;
        margin-left: 8px;
      }
      .text {
        color: rgba(47, 47, 77, 0.85);
      }
      .title {
        font-weight: 500;
        color: rgba(47, 47, 77, 0.9);
        line-height: 24px;
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        .title-right-icon {
          width: 20px;
          height: 20px;
        }
      }
    }
    .info-detail-picker {
      // padding-left: 56px;
      .am-list-content {
        font-size: 16px;
        font-weight: 400;
        color: rgba(47, 47, 77, 0.85);
        line-height: 22px;
      }
      .am-list-extra {
        font-size: 14px;
        color: rgba(47, 47, 77, 0.85);
      }
    }
    .info-detail-input {
      // padding-left: 56px;
      .am-input-label {
        font-size: 16px;
        font-weight: 400;
        color: rgba(47, 47, 77, 0.85);
        line-height: 22px;
      }
      input {
        text-align: right;
        font-size: 14px;
        color: rgba(47, 47, 77, 0.85);
      }
    }
    .info-checkBox {
      position: absolute;
      top: 0;
      right: 0;
      .am-list-thumb {
        margin-right: 0;
      }
      .am-checkbox.am-checkbox-checked .am-checkbox-inner {
        background: #202078;
        border-color: #202078;
      }
    }
    .info-text {
      // padding-left: 56px;
      .am-list-content {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 400;
        color: rgba(47, 47, 77, 0.85);
        line-height: 22px;
        white-space: unset;
        div:first-child {
          flex-shrink: 0;
          margin-right: 8px;
        }
      }
    }
  }
  .info-submit-btn {
    margin: 24px 0;
    background: #202078;
    color: #fff;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    border-radius: 20px;
  }

  .info-avatar {
    padding: 14px 8px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
    .info-avatar-img {
      width: 72px;
      height: 72px;
      border-radius: 50%;
      margin-right: 16px;
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
      border: 2px solid rgba(198, 0, 13, 0.09);
    }
    .info-avatar-btn {
      padding: 4px 12px;
      border-radius: 16px;
      border: 1px solid rgba(0, 0, 0, 0.09);
      position: relative;
    }
    .userAvatar {
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
      width: 90px;
      height: 28px;
    }
  }
}

.info-success {
  text-align: center;
  img {
    padding-top: 44px;
    display: block;
    margin: 0 auto 24px;
  }
  .info-success-title {
    font-size: 24px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 33px;
    margin-bottom: 15px;
  }
  .info-success-desc {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    line-height: 20px;
  }
  .info-success-btn {
    position: fixed;
    height: 40px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 40px;
    bottom: 40px;
    width: 100%;
    left: 0;
    padding: 0 16px;
    box-sizing: border-box;
    & > div {
      background: rgba(255, 255, 255, 1);
      border-radius: 24px;
      border: 1px solid rgba(0, 0, 0, 0.09);
    }
  }
}
.info-view {
  padding: 0px 24px 50px;
  .info-view-text {
    padding: 24px 0;
    font-size: 16px;
    font-weight: 500;
    color: rgba(47, 47, 77, 0.9);
    line-height: 22px;
  }
  .am-button {
    background: #202078;
    border-radius: 4px;
    span {
      font-size: 16px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      line-height: 22px;
    }
  }
}
.info-view-btn {
  position: fixed !important;
  bottom: 0;
  width: 100%;
  left: 0;
  background: #202078;
  color: #fff;
  z-index: 1;
}
