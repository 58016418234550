.nav-list {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  background: #f8f8f8;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 99;
  padding-bottom: constant(safe-area-inset-bottom); /* iOS 11.0 */
  padding-bottom: env(safe-area-inset-bottom);
}
.nav-list a {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  padding: 5px 0;
  width: 33%;
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
}
.nav-list p {
  margin: 1px auto;
  text-align: center;
}
.nav-list a.active {
  color: #202078;
}

.nav-list .mobile-icon {
  width: 24px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  height: 34px;
  background-position: center;
  margin: 0 auto;
}
.nav-list .mobile-icon.mobile-icon-index {
  background-image: url(../images/homeIcon.png);
}
.nav-list .mobile-icon.mobile-icon-activity {
  background-image: url(../images/activityIcon.png);
}
.nav-list .mobile-icon.mobile-icon-youth {
  background-image: url(../images/youthIcon.png);
}
.nav-list .mobile-icon.mobile-icon-per {
  background-image: url(../images/userIcon.png);
}

.nav-list .active .mobile-icon.mobile-icon-index {
  background-image: url(../images/homeIconActive.png);
}
.nav-list .active .mobile-icon.mobile-icon-activity {
  background-image: url(../images/activityIconActive.png);
}
.nav-list .active .mobile-icon.mobile-icon-youth {
  background-image: url(../images/youthIconActive.png);
}
.nav-list .active .mobile-icon.mobile-icon-per {
  background-image: url(../images/userIconActive.png);
}
