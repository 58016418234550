@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
.main-banner {
  padding: 0 16px;
  position: relative;
  z-index: 2;
  margin-bottom: 16px;
}
.banner {
  height: 160px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.09);
  border-radius: 6px;
}
.banner .banner-div {
  height: 100%;
  border-radius: 6px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.slick-dots {
  position: absolute;
  bottom: 3px;
}
.slick-dots li {
  margin: 0;
  transform: scale(0.6);
}
.slick-dots li button:before {
  color: #fff;
  opacity: 1;
  font-size: 12px;
}
.slick-dots li.slick-active button:before {
  color: #202078;
  opacity: 1;
}
