.mainBg {
  background-image: url("../images/mainBanner.png");
  width: 100%;
  height: 180px;
  background-size: cover;
  position: absolute;
  z-index: 1;
}
.main-top {
  position: relative;
  z-index: 10;
  margin-top: 24px;
  margin-bottom: 24px;
  height: 44px;
  .main-header {
    // position: fixed;
    height: 44px;
    padding: 0 16px;
    width: 100%;
    box-sizing: border-box;
    z-index: 99;
    position: relative;
    // background: #202078;
    .menu {
      position: relative;
      text-align: right;
      top: 50%;
      transform: translateY(-50%);
      .list {
        background: #fff;
        border-radius: 4px;
        width: 154px;
        font-size: 15px;
        font-weight: 400;
        color: #000;
        line-height: 15px;
        margin-top: 8px;
        position: absolute;
        right: 0;
        &::before {
          position: absolute;
          content: "";
          border: 10px dashed transparent;
          border-bottom: 10px solid #fff;
          top: -16px;
          right: 2px;
        }
        .list-item {
          color: #000;
          display: block;
          padding: 12px 18px;
          text-align: left;
          border-bottom: 1px solid rgba(0, 0, 0, 0.09);
        }
      }
    }
    .add-icon {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
    }
    .header-select-container {
      position: relative;
      text-align: center;
      .header-select {
        display: inline-block;
        text-align: center;
        margin: 0 auto;
        height: 44px;
        font-size: 17px;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        line-height: 44px;
        position: relative;
        background-color: transparent;
        background-image: url("../images/caretdown.png");
        background-repeat: no-repeat;
        background-size: 12px;
        background-position: right;
        border: none;
        padding-right: 14px;
      }
      .header-select-menu {
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.16);
        position: absolute;
        font-size: 15px;
        font-weight: 400;
        color: rgba(0, 0, 0, 1);
        line-height: 15px;
        left: 50%;
        transform: translateX(-50%);
        top: 44px;
        &::before {
          content: "";
          position: absolute;
          border: 10px dashed transparent;
          border-bottom: 10px solid #fff;
          top: -16px;
          left: 50%;
          transform: translateX(-50%);
        }
        & > div {
          padding: 12px 18px;
          border-bottom: 1px solid #ddd;
          white-space: nowrap;
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
}
.container {
  padding-bottom: 60px;
  position: relative;
  z-index: 5;
  // 首页校友活动
  .index-title {
    font-size: 20px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 28px;
    padding-left: 12px;
    position: relative;
    &::before {
      content: "";
      width: 4px;
      height: 12px;
      background: #202078;
      border-radius: 2px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .index-more {
    padding: 10px 0;
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: rgba(74, 74, 74, 1);
    line-height: 20px;
    background: rgba(255, 255, 255, 1);
    display: block;
    border-top: 1px solid rgba(0, 0, 0, 0.09);
  }
  .alumni-activity {
    background: rgba(255, 255, 255, 1);
    border-radius: 12px;
    padding: 16px 16px 0;
    margin-bottom: 16px;
    .alumni-activity-list {
      .list-item {
        padding-top: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.09);
        display: flex;
        &:last-child {
          border-bottom: 0;
        }
        .left {
          width: 94px;
          margin-right: 16px;
          height: 140px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          // flex: 1;
        }
        .right {
          flex: 1;
          display: flex;
          flex-wrap: wrap;
          .item-title {
            font-size: 20px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            line-height: 28px;
            width: 100%;
            max-height: 84px;
            overflow: hidden;
          }
          .item-desc-section {
            align-self: flex-end;
          }
          .item-desc {
            display: flex;
            align-items: center;
            color: rgba(0, 0, 0, 0.45);
            font-size: 14px;
            margin-top: 8px;
            img {
              margin-right: 4px;
            }
            span {
              line-height: 20px;
            }
          }
        }
      }
    }
    .alumni-activity-detail {
      margin-top: 16px;
      padding-bottom: 16px;
      color: rgba(0, 0, 0, 0.85);
      .title {
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        margin-bottom: 8px;
      }
      .desc {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        line-height: 20px;
        margin-bottom: 8px;
      }
      .sub-title {
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        margin-bottom: 8px;
      }
    }
  }
  .alumni-works {
    background: rgba(255, 255, 255, 1);
    border-radius: 12px;
    margin-bottom: 16px;
    padding: 16px 16px 0;
    .alumni-works-list {
      .list-item {
        padding: 16px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.09);
        display: block;
        &:last-child {
          border-bottom: none;
        }
        .alumni-works-img {
          width: 100%;
          height: 150px;
          background: #333;
          margin-bottom: 12px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
        }
        .alumni-works-title {
          font-size: 17px;
          font-weight: 500;
          color: rgba(51, 51, 51, 1);
          line-height: 24px;
          margin-bottom: 2px;
        }
        .alumni-works-author {
          font-size: 14px;
          font-weight: 300;
          color: rgba(153, 153, 153, 1);
          line-height: 20px;
          display: flex;
          align-items: center;
          img {
            margin-right: 4px;
          }
        }
      }
    }
  }

  .alumni-dynamic {
    background: rgba(255, 255, 255, 1);
    border-radius: 12px;
    margin-bottom: 16px;
    padding: 16px 16px 0;
    .alumni-dynamic-list {
      .list-item {
        display: flex;
        padding: 16px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.09);
        &:last-child {
          border-bottom: none;
        }
        .alumni-dynamic-left {
          margin-right: 16px;
          display: flex;
          flex-wrap: wrap;
          flex: 1;
          .alumni-dynamic-title {
            font-size: 17px;
            font-weight: 500;
            color: rgba(51, 51, 51, 1);
            line-height: 24px;
            width: 100%;
            height: 72px;
            overflow: hidden;
          }
          .alumni-dynamic-time {
            font-size: 14px;
            font-weight: 400;
            color: rgba(153, 153, 153, 1);
            line-height: 20px;
            align-self: flex-end;
          }
        }
        .alumni-dynamic-right {
          width: 128px;
          height: 92px;
          background: #333;
          background-size: cover;
          background-position: center;
        }
      }
    }
  }
  // 圈子正在进行的活动
  .main-activity {
    margin: 0 16px;
    padding: 12px 16px 16px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.09);
    border-radius: 6px;
    display: block;
    .main-activity-top {
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 16px;
      padding-left: 10px;
      position: relative;
      &::before {
        content: "";
        width: 6px;
        height: 6px;
        background: rgba(126, 211, 33, 1);
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .main-activity-item {
      display: flex;
      margin-top: 12px;
      .left {
        width: 53px;
        height: 80px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 12px;
      }
      .right {
        flex: 1;
        .item-title {
          font-size: 16px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          line-height: 22px;
        }
        .item-desc {
          margin-top: 8px;
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.45);
          line-height: 20px;
          img {
            margin-right: 4px;
          }
        }
      }
    }
  }
  // 个人信息
  .per-info {
    padding: 0 32px;
    display: flex;
    align-items: center;
    .per-info-img {
      margin-right: 12px;
      height: 72px;
      border-radius: 50%;
      border: 2px solid #fff;
      background: #fff;
    }
    .per-info-desc {
      .name {
        font-size: 20px;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        line-height: 28px;
      }
      .other {
        font-size: 14px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.85);
        line-height: 20px;
      }
    }
  }
  .per-notice {
    margin: 32px 24px 16px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.09);
    border-radius: 6px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    .divided {
      width: 1px;
      height: 40px;
      background: rgba(0, 0, 0, 0.09);
      margin: 0 16px;
    }
    .tips {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > div {
        display: flex;
        align-items: center;
        img {
          margin-right: 4px;
        }
        span {
          font-size: 14px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.5);
          line-height: 22px;
        }
      }
      .number {
        font-size: 16px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
        margin-right: 4px;
      }
    }
  }
}

// 圈子成员
.activity-member-list {
  background: rgba(255, 255, 255, 1);
  border-radius: 12px;
  margin-top: 16px;
  padding: 16px;
  min-height: 300px;
  position: relative;
  z-index: 5;
  .member-list-title {
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 28px;
    padding-bottom: 8px;
  }
  .member-list-item {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
    padding: 8px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 16px;
    }
  }
}
.page-container {
  .page-top {
    height: 180px;
    background-image: url(../images/pageBanner.png);
    background-size: cover;
    background-position: center;
    padding: 0 28px;
    overflow: hidden;
    position: relative;
    .page-top-icon {
      position: absolute;
      top: 16px;
      right: 16px;
      z-index: 2;
    }
    .page-top-title {
      font-size: 24px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      line-height: 33px;
      margin-top: 16px;
      margin-bottom: 3px;
      position: relative;
      &::before {
        content: "";
        width: 4px;
        height: 12px;
        background: rgba(255, 255, 255, 1);
        border-radius: 2px;
        position: absolute;
        left: -12px;
        top: 8px;
      }
    }
    .page-top-desc {
      font-size: 14px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.85);
      line-height: 20px;
    }
  }
  .alumni-activity {
    margin-top: -92px;
    border-radius: 12px 12px 0px 0px;
    padding-top: 0;
    position: relative;
    z-index: 1;
  }
  .alumni-works {
    margin-top: -12px;
    border-radius: 12px 12px 0px 0px;
    padding-top: 0;
    position: relative;
    z-index: 1;
  }
  .alumni-dynamic {
    margin-top: -92px;
    border-radius: 12px 12px 0px 0px;
    padding-top: 0;
    position: relative;
    z-index: 1;
  }
  .page-btn {
    padding: 0 16px;
    text-align: center;
    & > div {
      background: rgba(255, 255, 255, 1);
      border-radius: 19px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(74, 74, 74, 1);
      line-height: 20px;
      padding: 8px 0;
    }
  }
}

.youth-top {
  background-image: url("../images/youthMemoryBg.png");
  height: 168px;
  width: 100%;
  text-align: center;
  img {
    margin-top: 24px;
  }
}
.youth-works {
  border-radius: 12px;
  margin-bottom: 16px;
  padding: 0px 16px;
  margin-top: -96px;
  .youth-works-list {
    .list-item {
      padding: 16px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.09);
      display: block;
      &:last-child {
        border-bottom: none;
      }
      .youth-works-img {
        width: 100%;
        height: 150px;
        background: #333;
        margin-bottom: 12px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 6px;
      }
      .youth-works-title {
        font-size: 17px;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
        margin-bottom: 2px;
      }
      .youth-works-author {
        font-size: 14px;
        font-weight: 300;
        color: rgba(153, 153, 153, 1);
        line-height: 20px;
        display: flex;
        align-items: center;
        img {
          margin-right: 4px;
        }
      }
    }
  }
}
.member-modal-container {
  position: fixed;
  background: rgba(0, 0, 0, 0.35);
  height: 100vh;
  width: 100%;
  z-index: 999;
  top: 0;
  left: 0;
  .member-modal-body {
    width: 270px;
    height: 207px;
    background: rgba(255, 255, 255, 1);
    border-radius: 7px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .avatar {
      padding: 8px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 22px;
    }
    & > div {
      padding: 13px 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.09);
      display: flex;
      align-items: center;
      & > img {
        margin-right: 16px;
      }
    }
    .btn {
      font-size: 18px;
      font-weight: 400;
      color: #202078;
      line-height: 18px;
      text-align: center;
      border-bottom: none;
      display: block;
    }
  }
}

// 作品详情
.work-detail {
  background: rgba(255, 255, 255, 1);
  padding: 16px;
  .top {
    padding-bottom: 8px;
    border-bottom: 1px solid #f5f5f5;
    .title {
      font-size: 20px;
      font-weight: 500;
      color: rgba(51, 51, 51, 1);
      line-height: 28px;
    }
    .other {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(153, 153, 153, 1);
      line-height: 17px;
    }
  }
  .content {
    padding-top: 16px;
    font-size: 16px;
    font-weight: 400;
    color: rgba(56, 56, 56, 1);
    line-height: 26px;
    img {
      max-width: 100%;
      text-align: center;
      height: auto;
    }
  }
}
